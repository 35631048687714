import React from "react";

function Header({ logoUrl, customClasses }) {
  return (
    <header id="topnav" className="bg-transparent position-absolute">
      <div className="container-fluid">
        <div className="row pt-5">
          <div className={`col-7 col-sm-4 py-3 ms-auto ${customClasses}`}>
            <a className={`navbar-brand p-2 ${customClasses}`} href="/">
              <img src={logoUrl} alt="Logo" width="83" height="30" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
