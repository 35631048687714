import React, { useEffect } from "react";
import Form from "./Form";
import SEO from "./Seo";
import CookieBanner from "./Cookies";
import BackToTopButton from "./BackToTopButton";
import Header from "./Header";
import BgVite from "./assets/img/bg-pomodoro.webp";
import AgrigeniusVisual from "./assets/img/agrigenius-visual-pomodoro.webp";
import Dss from "./assets/img/dss.webp";
import SistemaDigitale from "./assets/img/sistema-digitale.webp";
import Icon1 from "./assets/img/icone/1-pomodoro.jpg";
import Icon2 from "./assets/img/icone/2-pomodoro.jpg";
import Icon3 from "./assets/img/icone/3-pomodoro.jpg";
import Icon4 from "./assets/img/icone/4-pomodoro.jpg";
import Icon5 from "./assets/img/icone/5-pomodoro.jpg";
import Logo from "./assets/img/logo.svg";
import { BiCheckCircle } from "react-icons/bi";
import jarallax from "jarallax/dist/jarallax.min";
import MobileButton from "./MobileButton";
import DesktopButton from "./DesktopButton";

const HomeSection = () => {
  useEffect(() => {
    // Inizializza l'effetto parallasse
    jarallax(document.querySelectorAll(".parallax"), {
      speed: 0.5,
    });
  }, []);

  return (
    <>
      <SEO
        title="Agrigenius® Pomodoro | La cassetta degli attrezzi digitale"
        description="Affronta le nuove sfide dell’agricoltura con Agrigenius® Pomodoro, il DSS che ti permette di ottenere una gestione del campo più efficiente"
      />
      <Header customClasses="bg-red" logoUrl={require("./assets/img/logo.svg").default} />
      <section
        className="pt-150 mb-3 mb-sm-5 d-table w-100 parallax z-1"
        style={{ background: `url(${BgVite}) center center` }}
        id="richiedi-preventivo"
        data-jarallax='{"speed": 0.5}'>
        <div className="bg-overlay"></div>
        <div className="container">
          <div className="row align-items-center mt-md-0">
            <div className="col-lg-7 col-md-6 order-1 order-md-1">
              <div className="title-heading">
                <h1 className="heading title-dark text-white mb-3 lh-1">
                  Agrigenius<sup>®</sup> Pomodoro: lo strumento che ti aiuta nella coltivazione del pomodoro da industria

                </h1>
                <p className="para-dark text-light h4 fw-light">
                La tecnologia a supporto di una delle colture più importanti del comparto agroalimentare italiano
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0 order-2 order-md-2">
              <div 
                className="card d-none d-sm-block shadow rounded border-0 me-lg-3 mb-form">
                <div className="card-header bg-red no-border text-white">
                  <div className="lead fw-bold mb-0 text-center text-uppercase">
                    Preventivo gratuito
                  </div>
                </div>
                <div className="card-body z-3">
                <Form
                buttonClasses="btn-red text-white"
                textcolor="text-red"
                    landingPageId="pomodoro"
                    initialTrackingImage="https://ad.doubleclick.net/ddm/activity/src=10044877;type=invmedia;cat=flbas005;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord="
                    trackingCode="https://ad.doubleclick.net/ddm/activity/src=10044877;type=invmedia;cat=flbas006;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section pt-0 pb-2 pb-sm-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6">
              <p>
              Negli ultimi anni la <strong>gestione della coltura del pomodoro da industria è diventata sempre più complessa</strong>, a causa dei <strong>cambiamenti climatici</strong>, dei fenomeni di <strong>resistenza</strong>, delle <strong>normative sempre più stringenti</strong> e di un mercato esigente che chiede <strong>maggiore sostenibilità</strong>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-3 pb-3 bg-red">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <p className="text-white mb-0">
              Per <strong>affrontare le nuove sfide dell’agricoltura</strong>, tecnici e agricoltori hanno bisogno di affiancare alle loro conoscenze e alla loro esperienza un valido alleato, una <strong>cassetta degli attrezzi digitale</strong> in grado di fornire <strong>informazioni utili per ottimizzare il lavoro in campo</strong>, con l’obiettivo di produrre in maniera sempre <strong>più redditizia e sostenibile</strong>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-1 pt-sm-3 pb-1 bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5">
              <img
                className="img-fluid"
                width="451"
                height="259"
                loading="lazy"
                src={Dss}
                alt="Dss"
              />
            </div>
            <div className="col-lg-7 col-md-7 mt-2 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-4">
                <div className="title mb-2 h4 text-red">
                Agrigenius<sup>®</sup> Pomodoro: la cassetta degli attrezzi digitale
                </div>
                <p>
                Un <strong>sistema di supporto alle decisioni (DSS)</strong> che incrocia i <strong>dati ambientali</strong> provenienti dalle stazioni agrometeorologiche in campo e dati meteo previsionali con <strong>sofisticati modelli di sviluppo fenologico</strong> della pianta con l’obiettivo di fornire a tecnici e agricoltori <strong>informazioni utili</strong> per intervenire in modo tempestivo.
                </p>
              </div>
            </div>
            <div className="col-lg-12 mt-2 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-4">
                <div className="title mb-2 h4 text-red">
                  Agrigenius<sup>®</sup> Pomodoro è in grado di:
                </div>
                <ul className="list-unstyled">
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#c31026"
                      />
                    </span>
                    fornire <strong>indici affidabili sulla situazione fitosanitaria</strong>, per la valutazione del rischio di infezione da <strong>peronospora, alternaria e batteriosi</strong> (macchiettatura batterica e maculatura batterica);
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#c31026"
                      />
                    </span>
                    segnalare <strong>criticità relative ai principali insetti del pomodoro</strong>, come <strong>nottua gialla, ragnetto rosso e tuta absoluta</strong>;

                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#c31026"
                      />
                    </span>
                   <strong>calcolare il livello di protezione</strong> (efficacia e persistenza) della coltura <strong>raggiunto con i trattamenti</strong> eseguiti;
                  </li>

                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#c31026"
                      />
                    </span>
                    fornire <strong>alert tempestivi in caso di criticità</strong>, per favorire interventi mirati.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-0 pt-sm-0 pb-0 bg-red">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4">
              <img
                className="img-fluid"
                width="382"
                height="203"
                loading="lazy"
                src={SistemaDigitale}
                alt="Sistema Digitale"
              />
            </div>
            <div className="col-lg-8 col-md-8 mt-2 pt-2 mt-sm-0 pt-sm-0">
              <div className="text-white ms-lg-2">
                <p className="mb-2 mb-sm-0">
                Nato dall’unione tra le competenze di <strong>BASF</strong> e <strong>l’esperienza di Hort@</strong>, Agrigenius<sup>®</sup> Pomodoro è il sistema digitale di supporto alle decisioni che permette a tecnici e agricoltori di <strong>monitorare lo stato di salute della coltura</strong> e di <strong>prendere decisioni razionali</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-whitt">
        <div className="container px-2 px-sm-0">
          <div className="row">
          <div className="col-12">
            <div className="section-title ms-lg-4 mt-4">
            <div className="title mb-2 h4 text-red">
              Agrigenius<sup>®</sup> Pomodoro ti permette di:
            </div>
          </div>
            </div>
          </div>
          <div className="row g-0 align-items-stretch">
            
            <div className="col-xl col-lg col-md">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid"
                    width="40"
                    height="40"
                    src={Icon5}
                    loading="lazy"
                    alt="Programmare gli interventi"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    <strong>programmare gli interventi</strong> in base allo{" "}
                    <strong>sviluppo fenologico</strong> della pianta
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl col-lg col-md">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid"
                    width="40"
                    height="40"
                    src={Icon4}
                    loading="lazy"
                    alt="Valutazione rischi"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    effettuare una{" "}
                    <strong>corretta valutazione dei rischi</strong> e
                    migliorare <strong>efficacia</strong> della strategia di
                    difesa e <strong>gestione della flora infestante</strong>

                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl col-lg col-md">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid"
                    width="40"
                    height="40"
                    src={Icon2}
                    loading="lazy"
                    alt="Registrare operazioni"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                 <strong>monitorare il contenuto idrico</strong> della coltura per <strong>gestire al meglio le irrigazioni</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl col-lg col-md">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid"
                    width="40"
                    height="40"
                    src={Icon3}
                    loading="lazy"
                    alt="Programmare gli interventi"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                  <strong>registrare tutte le operazioni</strong> effettuate in campo
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl col-lg col-md">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid"
                    width="40"
                    height="40"
                    src={Icon1}
                    loading="lazy"
                    alt="Ricevere allerte"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                  <strong>impostare avvisi di rischio</strong> per gli stress biotici e abiotici
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="pt-2 pb-2 d-table w-100 parallax"
        style={{
          background: `url(${AgrigeniusVisual}) center center`,
          zIndex: 1,
        }}
        id="home"
        data-jarallax='{"speed": 0.5}'>
        <div className="bg-overlay-white-pomodoro"></div>
        <div className="container">
          <div className="row align-items-center mt-md-0">
            <div className="col-lg-7 col-md-6 order-1 order-md-1">
              <div className="title-heading mt-4">
                <div className="title mb-2 h4 text-red">
                  Perché scegliere Agrigenius<sup>®</sup> Pomodoro?
                </div>
                <p className="para-dark">
                Agrigenius<sup>®</sup> Pomodoro <strong>integra e supporta</strong> la tua esperienza tecnica, aiutandoti a:  

                </p>
                <ul className="list-unstyled">
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#c31026"
                      />
                    </span>
                    avere <strong>maggior consapevolezza</strong> delle dinamiche che interessano il campo;
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#c31026"
                      />
                    </span>
                    <strong>anticipare i momenti critici</strong> per il controllo delle malattie;
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#c31026"
                      />
                    </span>
                    prendere decisioni informate e attuare <strong>interventi tempestivi</strong>;

                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#c31026"
                      />
                    </span>
                    <strong>ridurre i costi di gestione</strong> e lavorare in modo più sostenibile;
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#c31026"
                      />
                    </span>
                    <strong>ottimizzare la produzione</strong> in termini di
                    qualità e sanità.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-4 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="title mb-2 h4 text-red">
              La soluzione su misura per le tue esigenze
              </div>
              <p className="para-dark">
              Agrigenius<sup>®</sup> Pomodoro è <strong>disponibile in diverse versioni</strong>, per rispondere alle necessità e agli obiettivi di grandi aziende del settore, tecnici e consulenti, ma anche di realtà più contenute e piccoli agricoltori.
              </p>
            </div>
            <div className="col-12">
              <div className="d-flex bg-red-secondary position-relative py-4 mt-2 ms-5">
                <div className="box-Basf bg-red-secondary border-white position-absolute top-50 start-0 translate-middle align-self-sm-center align-self-start">
                  <img
                    className="img-fluid pb-2 mb-2 border-bottom"
                    width="83"
                    height="30"
                    src={Logo}
                    loading="lazy"
                    alt="Pro"
                  />
                  <p className="fs-7 mb-0 text-white text-center lh-sm">
                    PRO
                  </p>
                </div>
                <div className="text-white pe-2 pe-sm-3 box-Basf-text">
                  <p className="mb-2 lh-sm h4">
                    <strong>
                      La tecnologia incontra l'esperienza tecnica
                    </strong>
                  </p>
                  <p className="mb-0 lh-sm">
                  Una piattaforma web completa per la gestione professionale del tuo campo a 360°. Rielabora dati ambientali raccolti tramite stazione meteo in campo.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex bg-red position-relative py-4 mt-2 ms-5">
                <div className="box-Basf bg-red border-white position-absolute top-50 start-0 translate-middle align-self-sm-center align-self-start">
                  <img
                    className="img-fluid pb-2 mb-2 border-bottom"
                    width="83"
                    height="30"
                    src={Logo}
                    loading="lazy"
                    alt="Go"
                  />
                  <p className="fs-7 mb-0 text-white text-center lh-sm">
                    GO
                  </p>
                </div>
                <div className="text-white pe-2 pe-sm-3 box-Basf-text">
                  <p className="mb-2 lh-sm h4">
                    <strong>
                      Per una visione semplice, tascabile ed immediata
                    </strong>
                  </p>
                  <p className="mb-0 lh-sm">
                  App mobile per smartphone e tablet, dedicata alla difesa del pomodoro. Riassume tutte le informazioni necessarie in modo intuitivo, si collega ad un punto meteo virtuale.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex bg-red-secondary position-relative py-4 mt-2 ms-5">
                <div className="box-Basf bg-red-secondary border-white position-absolute top-50 start-0 translate-middle align-self-sm-center align-self-start">
                  <img
                    className="img-fluid pb-2 mb-2 border-bottom"
                    width="83"
                    height="30"
                    src={Logo}
                    loading="lazy"
                    alt="TECH"
                  />
                  <p className="fs-7 mb-0 text-white text-center lh-sm">
                    TECH
                  </p>
                </div>
                <div className="text-white pe-2 pe-sm-3 box-Basf-text">
                  <p className="mb-2 lh-sm h4">
                    <strong>
                    La soluzione per chi gestisce più realtà agricole
                    </strong>
                  </p>
                  <p className="mb-0 lh-sm">
                  Una piattaforma web che consente di monitorare più realtà produttive dislocate in areali lontani o con caratteristiche molto diverse tra loro.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5" id="form-mobile">
              <div className="card d-sm-none d-block shadow rounded border-0 me-lg-3">
                <div className="card-header bg-red no-border text-white">
                  <div className="lead fw-bold mb-0 text-center text-uppercase">
                    Preventivo gratuito
                  </div>
                </div>
                <div className="card-body z-3">
                <Form
                buttonClasses="btn-red text-white"
                textcolor="text-red"
                    landingPageId="pomodoro"
                    initialTrackingImage="https://ad.doubleclick.net/ddm/activity/src=10044877;type=invmedia;cat=flbas005;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord="
                    trackingCode="https://ad.doubleclick.net/ddm/activity/src=10044877;type=invmedia;cat=flbas006;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MobileButton buttonClasses="btn-red text-white" />
      <DesktopButton buttonClasses="btn-red text-white" />
      <BackToTopButton linkClasses="btn-red" />
      <CookieBanner buttonVariant="red text-white" />
    </>
  );
};

export default HomeSection;
