import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Importa BrowserRouter e Route
import Footer from "./Footer";
import Risorse from "./Risorse";
import CookiePolicy from "./CookiePolicy";
import CassettaAttrezzi from "./CassettaAttrezzi";
import TrattamentoDati from "./TrattamentoDati";
import Prevenzione from "./Prevenzione";
import Pomodoro from "./Pomodoro";
import Meteo from "./Meteo";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.min.css";
import "./assets/css/colors/green.css";
import "./assets/css/materialdesignicons.min.css";

function App() {
  return (
    <Router> {/* Avvolgo tutto con Router */}
      <>
        <Routes>
          <Route path="/" element={<CassettaAttrezzi />} />
          <Route path="/prevenzione" element={<Prevenzione />} />
          <Route path="/risorse" element={<Risorse />} /> 
          <Route path="/meteo" element={<Meteo />} /> 
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/trattamento-dati" element={<TrattamentoDati />} />
          <Route path="/pomodoro" element={<Pomodoro />} />
        
        </Routes>
        <Footer />
      </>
    </Router>
  );
}

export default App;
