import React, { useState, useEffect } from "react";

const MobileButton = ({ buttonClasses }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const formMobile = document.getElementById("form-mobile");
      if (formMobile) {
        const top = formMobile.getBoundingClientRect().top;
        setIsVisible(top > window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button
          type="button"
          className={`btn fixed-bottom w-100 d-lg-none ${buttonClasses}`}
          onClick={() => {
            window.location.href = "#form-mobile";
          }}
        >
          RICHIEDI UN PREVENTIVO GRATUITO
        </button>
      )}
    </>
  );
};

export default MobileButton;
