import React, { useState, useEffect } from 'react';
import { Toast, Button } from 'react-bootstrap';
import { useCookies } from 'react-cookie'; // Importa useCookies dalla libreria react-cookie

const CookieBanner = ({ buttonVariant }) => {
  const [showBanner, setShowBanner] = useState(true);
  const [cookies, setCookie] = useCookies(['acceptedCookies']); // Utilizza useCookies per gestire i cookie

  useEffect(() => {
    const acceptedCookies = cookies.acceptedCookies;
    if (acceptedCookies) {
      setShowBanner(false);
    }
  }, [cookies.acceptedCookies]); // Aggiungi cookies.acceptedCookies come dipendenza dell'effetto useEffect

  const acceptCookies = () => {
    setCookie('acceptedCookies', 'true', { path: '/' }); // Imposta il cookie quando l'utente accetta
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <Toast
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
            minWidth: '200px',
            zIndex: '1000' // Imposta un valore alto per z-index
          }}
          className="d-md-none" // Nasconde il banner su desktop
          onClose={() => setShowBanner(false)}
        >
          <Toast.Header closeButton={false}>
            <strong className="mr-auto">Cookie Policy</strong>
          </Toast.Header>
          <Toast.Body>
            <p className='fs-7 lh-2'>
              Per noi la protezione della tua privacy è importante!
              Cliccando su “Accetta tutti i cookie”, l'utente accetta di memorizzare i cookie sul dispositivo per migliorare la navigazione del sito, analizzare l'utilizzo del sito e assistere alle nostre attività di marketing.
            </p>
            <Button onClick={acceptCookies} variant={buttonVariant} size="sm" className="float-right ms-2">Accetta tutti i cookie</Button>
          </Toast.Body>
        </Toast>
      )}
      {showBanner && (
        <Toast
          style={{
            position: 'fixed',
            bottom: '80px',
            left: '10px',
            minWidth: '200px',
            zIndex: '1000' // Imposta un valore alto per z-index
          }}
          className="d-none d-md-block" // Nasconde il banner su mobile
          onClose={() => setShowBanner(false)}
        >
          <Toast.Header closeButton={false}>
            <strong className="mr-auto">Cookie Policy</strong>
          </Toast.Header>
          <Toast.Body>
            <p className='fs-7 lh-2'>
              Per noi la protezione della tua privacy è importante!
              Cliccando su “Accetta tutti i cookie”, l'utente accetta di memorizzare i cookie sul dispositivo per migliorare la navigazione del sito, analizzare l'utilizzo del sito e assistere alle nostre attività di marketing.
            </p>
            <Button onClick={acceptCookies} variant={buttonVariant} size="sm" className="float-right ms-2">Accetta tutti i cookie</Button>
          </Toast.Body>
        </Toast>
      )}
    </>
  );
};

export default CookieBanner;
