import React, { useEffect } from "react";
import SEO from "./Seo";
import CookieBanner from "./Cookies";
import BackToTopButton from "./BackToTopButton";
import Header from "./Header";

import jarallax from "jarallax/dist/jarallax.min";

const HomeSection = () => {
  useEffect(() => {
    // Inizializza l'effetto parallasse
    jarallax(document.querySelectorAll(".parallax"), {
      speed: 0.5,
    });
  }, []);

  return (
    <>
      <SEO
        title="Agrigenius® | TRATTAMENTO DEI DATI PER FINALITÀ DI MARKETING"
        description="La tecnologia a supporto della sostenibilità. Scopri Agrigenius® Vite, il DSS specifico per il vigneto, per ottenere una gestione più efficiente"
      />
      <Header logoUrl={require("./assets/img/logo.svg").default} />
      <div className="section pt-5 pb-4 mt-5 bg-white">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="title mb-2 h4 text-secondary-green">
              TRATTAMENTO DEI DATI PER FINALITÀ DI MARKETING
              </h1>
              <p>
              Accetto che BASF Italia S.p.A. ("BASF") e le altre affiliate del Gruppo BASF possano contattarmi tramite i canali di comunicazione selezionati allo scopo di informarmi sui prodotti, i servizi e gli eventi di BASF che BASF considera di mio interesse e di condurre sondaggi tra i clienti. Al fine di determinare quali informazioni siano di mio interesse, BASF può raccogliere, analizzare e condividere tra tali società i miei dati personali, informazioni sulle mie esigenze e interessi professionali (compresi quelli derivanti dalle mie interazioni rispetto a queste comunicazioni di marketing e dal mio rapporto commerciale in generale con BASF). Ulteriori dettagli sulla portata del mio consenso e sul mio diritto di revocarlo in qualsiasi momento sono disponibili di seguito. Informazioni sul trattamento e sulla protezione dei dati personali all'interno del Gruppo BASF sono disponibili all'indirizzo:{" "}
                <a
                  href="https://www.basf.com/it/it/legal/data-protection-at-basf.html "
                  target="_blank"
                  title="https://www.basf.com/it/it/legal/data-protection-at-basf.html ">
                  https://www.basf.com/it/it/legal/data-protection-at-basf.html{" "}
                </a>
              </p>

              <p>
              Spuntando la casella, acconsento che BASF Italia S.p.A. ("BASF") e le altre società del Gruppo che forniscono prodotti e servizi ritenuti interessanti per me possano contattarmi tramite i canali di comunicazione che ho selezionato allo scopo di informarmi sui propri prodotti e servizi (ad es. eventi, webinar, app, programmi fedeltà, servizi di consulenza, ecc.) e di condurre indagini di mercato. Comprendo che le chiamate telefoniche e i messaggi possano avvenire utilizzando la tecnologia di composizione automatica. Comprendo che non sono tenuto a dare il consenso come condizione per l'acquisto di beni o servizi. Per far sì che le informazioni siano interessanti per me e la mia azienda e adatte alle mie esigenze professionali, BASF può combinare i dati personali che ha raccolto su di me nel corso del mio rapporto con BASF come cliente o utente di suoi prodotti e servizi (in particolare i miei dati contrattuali e anagrafici, i prodotti che ho acquistato, campioni, demo, brochure o white paper che ho richiesto o scaricato, webinar per i quali mi sono registrato, sondaggi che ho completato, ecc.) all'interno di BASF e analizzarli per i miei possibili interessi. A tal fine, BASF può anche valutare le mie interazioni rispetto a questi messaggi di marketing (ad es. raccogliendo informazioni sull'apertura di una newsletter e sui link cliccati) e includere queste valutazioni nelle analisi per l'invio di messaggi di marketing diretto basati sui miei interessi. Il mio consenso è valido fino alla revoca, che posso fare in qualsiasi momento con effetto per il futuro, ad es. cliccando sul link corrispondente contenuto in ogni messaggio di marketing.

              </p>
            </div>
          </div>
        </div>
      </div>

      <BackToTopButton />
      <CookieBanner />
    </>
  );
};

export default HomeSection;
