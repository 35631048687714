import React, { useState, useEffect } from "react";

const DesktopButton = ({ buttonClasses }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const top = window.scrollY;
      setIsVisible(top > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button
          type="button"
          className={`btn fixed-bottom w-100 d-none d-sm-block ${buttonClasses}`}
          onClick={() => {
            window.location.href = "#richiedi-preventivo";
          }}
        >
          RICHIEDI UN PREVENTIVO GRATUITO
        </button>
      )}
    </>
  );
};

export default DesktopButton;
