import React, { useEffect } from "react";
import SEO from "./Seo";
import CookieBanner from "./Cookies";
import BackToTopButton from "./BackToTopButton";
import Header from "./Header";


import jarallax from "jarallax/dist/jarallax.min";


const HomeSection = () => {
  useEffect(() => {
    // Inizializza l'effetto parallasse
    jarallax(document.querySelectorAll(".parallax"), {
      speed: 0.5,
    });
  }, []);
  

  return (
    <>
      <SEO
        title="Agrigenius® | Cookies"
        description="La tecnologia a supporto della sostenibilità. Scopri Agrigenius® Vite, il DSS specifico per il vigneto, per ottenere una gestione più efficiente"
      />
      <Header logoUrl={require("./assets/img/logo.svg").default} />
      <div className="section pt-5 pb-4 mt-5 bg-white">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="title mb-2 h4 text-secondary-green">COOKIES POLICY</h1>
              <p>
              Quando si visita qualsiasi sito Web, questo può memorizzare o recuperare informazioni sul tuo browser, in gran parte sotto forma di cookie. Queste informazioni potrebbero essere su di te, le tue preferenze o il tuo dispositivo e sono utilizzate in gran parte per far funzionare il sito secondo le tue aspettative. Le informazioni di solito non ti identificano direttamente, ma possono fornire un'esperienza Web più personalizzata. Poiché rispettiamo il tuo diritto alla privacy, è possibile scegliere di non consentire alcuni tipi di cookie. Clicca sulle intestazioni delle diverse categorie per saperne di più e modificare le impostazioni predefinite. Tuttavia, il bloccaggio di alcuni tipi di cookie può avere impatto sulla tua esperienza del sito e dei servizi che siamo in grado di offrire.
              </p>
              <h2 className="title mb-2 h4 text-secondary-green">Cookie strettamente necessari
</h2>
<p>Sempre attivi
</p>
<p>Questi cookie sono necessari per il funzionamento del sito e non possono essere disattivati ​​nei nostri sistemi. Di solito vengono impostati solo in risposta alle azioni da te effettuate che costituiscono una richiesta di servizi, come l'impostazione delle preferenze di privacy, l'accesso o la compilazione di moduli. È possibile impostare il browser per bloccare o avere avvisi riguardo questi cookie, ma di conseguenza alcune parti del sito non funzioneranno. Questi cookie non archiviano informazioni personali.
</p>
<h3 className="title mb-2 h4 text-secondary-green">Tracciamento e pixel</h3>
<p>Il nostro sito include cookie di terze parti per il tracciamento della conversione che sono utilizzati da fornitori di servizi esterni per raccogliere informazioni sull'interazione degli utenti con le nostre pagine e i nostri moduli. Questi cookie consentono a terze parti di monitorare e tracciare l'efficacia delle nostre campagne pubblicitarie e di marketing online. Le informazioni raccolte tramite questi cookie possono includere dati quali l'indirizzo IP dell'utente, il tipo di browser, il sistema operativo, la data e l'ora delle visite al sito, le pagine visualizzate e i click effettuati. Queste informazioni vengono esclusivamente utilizzate per valutare il rendimento delle nostre attività di marketing e per ottimizzare le strategie di pubblicità e non sono cedute a terzi per scopi diversi.
</p>
            </div>
          </div>
        </div>
      </div>
    
      <BackToTopButton />
      <CookieBanner />
    </>
  );
};

export default HomeSection;
