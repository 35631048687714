import React from 'react';

const BackToTopButton = ({ linkClasses }) => {
  const topFunction = () => {
    // Logica per tornare in cima alla pagina
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <a href="#" onClick={topFunction} id="back-to-top" className={`btn btn-icon back-to-top ${linkClasses}`} style={{ display: 'block' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-up icons">
        <line x1="12" y1="19" x2="12" y2="5"></line>
        <polyline points="5 12 12 5 19 12"></polyline>
      </svg>
    </a>
  );
};

export default BackToTopButton;
