import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Toast } from "react-bootstrap";
import { MdPerson, MdBusiness, MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
import sha256 from 'crypto-js/sha256'; // Aggiungi questa importazione per l'hash dell'email

const Form = ({
                landingPageId,
                initialTrackingImage,
                trackingCode,
                buttonClasses,
                textcolor,
                page,
                uid,          // Ricevi l'UID come prop
                setUid        // Ricevi la funzione setUid
              }) => {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    company: "",
    region: "",
    phone: "",
    email: "",
    marketingConsent: false,
    termsAccepted: false,
    landingPageId: landingPageId,
    uid: "", // Mantieni il campo UID anche localmente per il calcolo
    page: page // Mantieni il campo page
  });

  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [showToast, setShowToast] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [initialTrackingLoaded, setInitialTrackingLoaded] = useState(false);
  const [trackingCodeLoaded, setTrackingCodeLoaded] = useState(false);

  // Funzione per calcolare l'UID hashato
  const generateUid = (email) => {
    return sha256(email).toString(); // Genera l'hash dell'email
  };

  useEffect(() => {
    // Calcola l'UID ogni volta che cambia l'email
    if (formData.email) {
      const newUid = generateUid(formData.email);  // Calcola l'UID
      setFormData((prevState) => ({
        ...prevState,
        uid: newUid // Aggiorna lo stato locale
      }));
      setUid(newUid);  // Passa l'UID al componente padre
    }
  }, [formData.email, setUid]);

  useEffect(() => {
    // Load initial tracking image on component mount
    activateTracking(initialTrackingImage, 3001);
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    // Load tracking code when form is submitted and data is valid
    if (formSubmitted && !trackingCodeLoaded) {
      activateTracking(trackingCode, 3002);
    }
  }, [formSubmitted, trackingCode, trackingCodeLoaded]);

  const validateFormData = () => {
    const { name, surname, company, region, phone, email, termsAccepted } = formData;
    if (
      !name.trim() ||
      !surname.trim() ||
      !company.trim() ||
      !region.trim() ||
      !phone.trim() ||
      !validateEmail(email) ||
      !termsAccepted
    ) {
      setToastMessage(
        "Ops, compila correttamente tutti i campi obbligatori con *"
      );
      setToastVariant("danger");
      setShowToast(true);
      return false;
    }
    return true;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const addUser = () => {
    if (!validateFormData()) {
      return;
    }
    setLoading(true);
    let myport = 3001;
    if (window.location.hostname === "mirko.hr-g.com") myport = 3002;
    Axios.post(
      "https://" + window.location.hostname + ":" + myport + "/create",
      formData
    )
      .then((response) => {
        const token = response.data.token;
        if (token) {
          setToastMessage(
            "Richiesta inviata con successo! Verrai ricontattato telefonicamente per approfondire le caratteristiche di Agrigenius®."
          );
          setToastVariant("success");
          setShowToast(true);
          setFormData({
            name: "",
            surname: "",
            company: "",
            region: "",
            phone: "",
            email: "",
            marketingConsent: false,
            termsAccepted: false,
            landingPageId: landingPageId,
            uid: "", // Reset UID
            page: page // Reset page
          });
          setFormSubmitted(true); // Set form submitted state to true
        }
      })
      .catch((err) => {
        console.error(err);
        setToastMessage(
          "Si è verificato un errore. Per favore, riprova più tardi."
        );
        setToastVariant("danger");
        setShowToast(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const activateTracking = (trackingSrc, port) => {
    if (port === 3001 && !initialTrackingLoaded) {
      const trackingImg = new Image();
      trackingImg.onload = () => {
        setInitialTrackingLoaded(true);
      };
      trackingImg.src = trackingSrc;
      trackingImg.style.display = "none";
      document.body.appendChild(trackingImg);
    } else if (port === 3002 && !trackingCodeLoaded) {
      const trackingImg = new Image();
      trackingImg.onload = () => {
        setTrackingCodeLoaded(true);
      };
      trackingImg.src = trackingSrc;
      trackingImg.style.display = "none";
      document.body.appendChild(trackingImg);
    }
  };

  const handleSubmit = () => {
    if (!validateFormData()) {
      return;
    }
    setLoading(true);
    addUser();
  };

  return (
    <form>
      <div className="d-flex flex-row g-2 align-items-center justify-content-center justify-content-lg-center"></div>

      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        bg={toastVariant}
        className={`text-white mt-2 mb-2 w-100`}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>

      <div className="row g-2">
        <div className="col-md-6">
          <div className="form-outline mb-2">
            <label className="form-label mb-0" htmlFor="name">
              Nome <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <span className="input-group-text bg-white">
                <MdPerson />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Nome"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                autoComplete="given-name"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-outline mb-2">
            <label className="form-label mb-0" htmlFor="surname">
              Cognome <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <span className="input-group-text bg-white">
                <MdPerson />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Cognome"
                name="surname"
                id="surname"
                autoComplete="family-name"
                value={formData.surname}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row g-2">
        <div className="col-md-6">
          <div className="form-outline mb-2">
            <label className="form-label mb-0" htmlFor="company">
              Azienda <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <span className="input-group-text bg-white">
                <MdBusiness />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Azienda"
                name="company"
                id="company"
                value={formData.company}
                onChange={handleInputChange}
                autoComplete="organization"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-outline mb-2">
            <label className="form-label mb-0" htmlFor="region">
              Provincia <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <span className="input-group-text bg-white">
                <MdLocationOn />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Provincia"
                name="region"
                id="region"
                value={formData.region}
                onChange={handleInputChange}
                autoComplete="street-address"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row g-2">
        <div className="col-md-6">
          <div className="form-outline mb-2">
            <label className="form-label mb-0" htmlFor="phone">
              Telefono <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <span className="input-group-text bg-white">
                <MdPhone />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Telefono"
                name="phone"
                id="phone"
                value={formData.phone}
                onChange={handleInputChange}
                autoComplete="tel"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-outline mb-2">
            <label className="form-label mb-0" htmlFor="email">
              Indirizzo Email <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <span className="input-group-text bg-white">
                <MdEmail />
              </span>
              <input
                type="email"
                className="form-control"
                placeholder="Indirizzo Email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                autoComplete="mail"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row g-2 mt-2">
        <div className="col-12">
          <div className="form-check mb-1">
            <input
              className="form-check-input"
              type="checkbox"
              id="termsCheck"
              checked={formData.termsAccepted}
              onChange={handleInputChange}
              name="termsAccepted"
            />
            <label className="form-check-label" htmlFor="termsCheck">
              Dichiaro di aver preso visione dell’
              <a
                href="https://www.basf.com/it/it/legal/data-protection-at-basf.html#accordion_v2-f838f16a59-item-26961bd037"
                target="_blank"
                className={`${textcolor}`}
                title="informativa sulla privacy"
              >
                informativa sulla privacy
              </a>{" "}
              e di acconsentire al trattamento dei miei dati al fine di essere
              ricontattato in merito al prodotto Agrigenius® Vite{" "}
              <span className="text-danger">*</span>
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="marketingConsentCheck"
              checked={formData.marketingConsent}
              onChange={handleInputChange}
              name="marketingConsent"
            />
            <label className="form-check-label" htmlFor="marketingConsentCheck">
              Acconsento al{" "}
              <a
                href="trattamento-dati"
                target="_blank"
                className={`${textcolor}`}
                title="trattamento dei dati"
              >
                trattamento dei dati
              </a>{" "}
              per finalità di marketing
            </label>
          </div>

          <div className="d-grid mt-1 pt-1">
            <button
              type="button"
              className={`btn btn-lg mt-2 me-2 ${buttonClasses}`}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Caricamento..." : "Richiedi un preventivo!"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
